import IHandler from './IHandler';
import {
  CreateResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateParams,
} from 'react-admin';
import { getJson, postJson } from '../../../utils/api';

export default class UserBalanceHandler implements IHandler {
  static async getOneHandler<RecordType extends RaRecord = any>(
    resource: string,
    params?: GetOneParams<any>
  ): Promise<GetOneResult<RecordType>> {
    const id = params?.id;
    return getJson(`/admin/user/${id}/balance`).then(async (response) => {
      const { data } = await response.json();

      data.id = id;

      return {
        data: {
          ...data,
        },
      };
    });
  }

  static async updateHandler<RecordType extends RaRecord = any>(
    resource: string,
    params?: UpdateParams<any>
  ): Promise<CreateResult<RecordType>> {
    const payload = params?.data || {};
    const { amount, reason, id } = payload;

    return postJson(`/admin/user/${id}/balance/adjust`, {
      amount,
      reason,
    }).then(async (response) => {
      const { data } = await response.json();
      data.id = data.userId;
      return {
        data: {
          ...data,
        },
      };
    });
  }

  static async chargeHandler<RecordType extends RaRecord = any>(
    userId: string,
    amount: number,
    description: string
  ): Promise<CreateResult<RecordType>> {
    return postJson(`/admin/user/${userId}/balance/charge`, {
      amount,
      description,
    }).then(async (response) => {
      await response.json();
      return {
        data: {
          id: userId,
        },
      } as any;
    });
  }

  static async getMany<RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<RecordType>> {
    const { ids } = params;
    // split ids into chunks of 10
    const chunks = [];
    for (let i = 0; i < ids.length; i += 10) {
      chunks.push(ids.slice(i, i + 10));
    }
    const results = (
      await Promise.all(
        chunks.map((userIds) =>
          postJson(`/admin/balance?skippending=1`, { userIds })
            .then(async (response) => {
              const { data } = await response.json();

              return (data as any[]).map((d) => ({
                id: d.userId,
                ...d,
              }));
            })
            .catch(() => null)
        )
      )
    ).flat();
    return {
      data: results.filter((r) => r != null),
    };
  }
}
